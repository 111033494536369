import React from "react"
import { graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Células" />
        {/* Ejemplo de una célula: */}
        <article>
          <header>
            <h1>Formato de células <em>(abiertas)</em></h1>
          </header>

          <section>
            <ul>
              <li><strong>Bienvida</strong> - 5 minutos</li>
              <li><strong>Oración/Rema</strong> - 5 minutos</li>
              <li><strong>Alabanza</strong> - 5 minutos</li>
              <li><strong>Enseñanza Semanal</strong> - 20 minutos</li>
              <li><strong>Ministración</strong> - 5 minutos</li>
              <li><strong>Anuncios/Desafíos</strong> - 5 minutos</li>
              <li><strong>Despedida</strong> - 5 minutos</li>
            </ul>
          </section>
        </article>

      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
  `
  
  // allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
  //   edges {
  //     node {
  //       excerpt
  //       fields {
  //         slug
  //       }
  //       frontmatter {
  //         date(formatString: "MMMM DD, YYYY")
  //         title
  //         description
  //       }
  //     }
  //   }
  // }